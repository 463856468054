import logo from './logo.svg';
import './App.css';
import $ from 'jquery';
import TextLoop from "react-text-loop";
import mixpanel from 'mixpanel-browser';
mixpanel.init('14a254b52c9c0cff3b42976b54cb9138');
mixpanel.track('Landed on Homepage')


function App() {

  var betterYesEmojis = ["🧗‍♀️", "🙏", "📚", "🌄"]

  var animationDelay = 2500;

  animateHeadline($('.cd-headline'));
  
  function animateHeadline($headlines) {
     $headlines.each(function(){
        var headline = $(this);
        //trigger animation
        setTimeout(function(){ hideWord( headline.find('.is-visible') ) }, animationDelay);
        //other checks here ...
     });
  }

  function hideWord($word) {
      var nextWord = takeNext($word);
      switchWord($word, nextWord);
      setTimeout(function(){ hideWord(nextWord) }, animationDelay);
  }
  
  function takeNext($word) {
      return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
  }
  
  function switchWord($oldWord, $newWord) {
      $oldWord.removeClass('is-visible').addClass('is-hidden');
      $newWord.removeClass('is-hidden').addClass('is-visible');
  }

  function handleAppStoreButtonClick() {
    mixpanel.track('App Store Button Clicked', {
      'source': 'website'
    });
  }

  function handleBlogButtonClick() {
    mixpanel.track('Blog Button Clicked', {
      'source': 'website'
    });
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-replacement-animations">
          <div className="App-headline-emoji"><a className="wave">📲</a><a className="recycle">♻️</a>
            <TextLoop>
              <span>📚</span>
              <span>🙏</span>
              <span>🧗‍♀️</span>
              <span>🧘‍♂️</span>
              <span>✍️</span>
              <span>🙋‍♀️</span>
              <span>🗣</span>
              <span>🧘‍♂️</span>
              <splan>😴</splan>
              <span>🔨</span>
              <span>🤷‍♂️</span>
            </TextLoop>{" "}
          </div>
          <h3>
            <span>Clear Space for </span>
            <TextLoop>
              <span><i>reading</i></span>
              <span><i>prayer</i></span>
              <span><i>exercise</i></span>
              <span><i>being bored</i></span>
              <span><i>journaling</i></span>
              <span><i>friends</i></span>
              <span><i>a new language</i></span>
              <span><i>meditation</i></span>
              <span><i>sleep</i></span>
              <span><i>deep work</i></span>
              <span><i>serendipity</i></span>
            </TextLoop>{" "}
          </h3>
        </div>
        
        <p className="App-message">
          Recycle wasted time scrolling on apps into <em><b>time well spent.</b></em>
        </p> 
        <a
          className="App-itunes-link"
          onClick={handleAppStoreButtonClick} 
	  href="https://apps.apple.com/us/app/clearspace-less-screen-time/id1572515807"
          //href="https://apps.apple.com/us/app/getclearspace/id1538478652?ign-itscg=30200&ign-itsct=apps_box#?platform=iphone"
          //href="https://apps.apple.com/us/app/getclearspace/id1538478652?itsct=apps_box&amp;itscg=30200" 
        >
          <img
            className="Itunes-img" 
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-US?size=250x83&amp;releaseDate=1607299200&h=29c554a1cfdb04cb957f3daf632fe3e2" 
            alt="Download on the App Store" 
          />
        </a>
        <div className="App-links">
          <a
            className="App-link"
            href="/"
            rel="noopener noreferrer"
          >
            Home 
          </a>
          <a> · </a>
          <a
            className="App-link"
            href="https://www.notion.so/Privacy-Policy-05fb16506a294132acee914a8f6e5331"
            target="_blank"
            rel="noopener noreferrer"
          >
          Privacy Policy 
          </a>
          <a> · </a>
          <a
            className="App-link"
            href="https://docs.google.com/forms/d/e/1FAIpQLScxPf5YF47vHn-djsQuauMEMR1zalKYIR4Rp0SbmSNh0GLxJA/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
          Contact
          </a>
          <a> · </a>
          <a
            className="App-link"
            href="https://blog.getclearspace.com"
            onClick={handleBlogButtonClick} 
            target="_blank"
            rel="noopener noreferrer"
          >
          Blog
          </a>
          
        </div>
      </header>
    </div>
  );
}

export default App;
